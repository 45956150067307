@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;600;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    
    @font-face {
        font-family: "streamline-small";
        src:url("../fonts/streamline-small.eot");
        src:url("../fonts/streamline-small.eot?#iefix") format("embedded-opentype"),
          url("../fonts/streamline-small.woff") format("woff"),
          url("../fonts/streamline-small.ttf") format("truetype"),
          url("../fonts/streamline-small.svg#streamline-small") format("svg");
        font-weight: normal;
        font-style: normal;
      
    }
  
    @font-face {
      font-family: "social";
      src:url("../fonts/social.eot");
      src:url("../fonts/social.eot?#iefix") format("embedded-opentype"),
        url("../fonts/social.woff") format("woff"),
        url("../fonts/social.ttf") format("truetype"),
        url("../fonts/social.svg#social") format("svg");
      font-weight: normal;
      font-style: normal;
    
    }
  
    @font-face {
      font-family: "template";
      src:url("../fonts/template.eot");
      src:url("../fonts/template.eot?#iefix") format("embedded-opentype"),
        url("../fonts/template.woff") format("woff"),
        url("../fonts/template.ttf") format("truetype"),
        url("../fonts/template.svg#template") format("svg");
      font-weight: normal;
      font-style: normal;
    
    }
  
}

.button {
    @apply inline-flex items-center px-4 py-2 text-xs font-semibold tracking-widest text-gray-800 transition duration-150 ease-in-out bg-gray-100 border border-transparent rounded-md ring-gray-300;
}

.button:hover {
    @apply bg-gray-200;
}

.button:active, .button:focus {
    @apply bg-gray-300 ring;
}

.button.button-primary {
    @apply bg-yellow-500 text-white !important;
}

.button-primary:hover, .button.button-primary:active, .button.button-primary:focus {
    @apply bg-yellow-600 !important;
}



  
.active{
    @apply bg-yellow-400;
}


.active div.font-streamline{
    @apply text-white;
}

.active span{
    @apply border-yellow-400 opacity-100;
}